

.App {
  text-align: center;
}

.AppGral {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* background: rgb(65, 51, 121); */
  background: linear-gradient(180deg, rgba(65, 51, 121, 1) 1%, rgba(20, 134, 178, 1) 100%);
}

.App-logo {
  /* height: 400px; */
  margin: 10% 20%;
}

.header {
  color: #FFFFFF;
  font-size: 4vw;
  /* align-self: center;
  width: 100%; */
}

.Links-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 5%;
}

a {
  width: 80%;
  text-decoration: none;

  /* margin: 0 25%; */
}

p {
  text-align: center;
  color: #FFFFFF;
  font-size: 2.2vw;
  /* margin-bottom: 5%; */
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}